import type { ActivatedRouteSnapshot } from '@angular/router';
import type { Observable } from 'rxjs';

import { inject } from '@angular/core';
import { CommunicationsWebservice } from '@webservices/obendy/communications.webservice';
import { of } from 'rxjs';

export const partnerIdResolver = (route: ActivatedRouteSnapshot): Observable<string> => {
  const partnerId = route.paramMap.get('partnerId')!;
  inject(CommunicationsWebservice).setPartnerId(partnerId);
  return of(partnerId);
};
