import type { ActivatedRouteSnapshot, CanActivateFn, UrlTree } from '@angular/router';

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { VersionsService } from '@features/partners/builder/settings/versions/versions.service';

export const versionsGuard: CanActivateFn = ({ paramMap }: ActivatedRouteSnapshot): boolean | UrlTree => {
  const versionService = inject(VersionsService);
  const partnerId = paramMap.get('partnerId');

  if (partnerId === null) {
    return inject(Router).createUrlTree(['/']);
  }

  versionService.partnerId = partnerId;
  return true;
};
