import type { Route } from '@angular/router';

import { canDeactivateGuard } from '@features/partners/builder/routes/canDeactivate.guard';
import { SETTINGS_ROUTING } from '@features/partners/builder/settings/routes';
import { signInMethodsResolver } from '@features/partners/builder/settings/sign-in-methods/sign-in-methods.resolver';
import { partnerIdResolver } from '@features/partners/builder/settings/support/support.resolver';
import { trackersResolver } from '@features/partners/builder/settings/trackers/trackers.resolver';
import { versionsGuard } from '@features/partners/builder/settings/versions/versions.guard';

const PROFILE_ROUTE = import('@features/partners/builder/settings/profile/routes/profile.routes');

export const SETTINGS_ROUTES: Route[] = [
  {
    path: '',
    redirectTo: SETTINGS_ROUTING.Branding,
    pathMatch: 'full',
  },
  {
    path: SETTINGS_ROUTING.Profile,
    title: SETTINGS_ROUTING.Profile,
    loadChildren: async () => (await PROFILE_ROUTE).PROFILE_ROUTES,
  },
  {
    path: SETTINGS_ROUTING.Branding,
    title: SETTINGS_ROUTING.Branding,
    loadComponent: async () =>
      (await import('@features/partners/builder/settings/branding/branding.component')).BrandingComponent,
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: SETTINGS_ROUTING.Menu,
    loadChildren: async () => (await import('@features/partners/builder/settings/menu/routes/menu.routes')).MENU_ROUTES,
  },
  {
    path: SETTINGS_ROUTING.Versions,
    loadComponent: async () =>
      (await import('@features/partners/builder/settings/versions/versions.component')).VersionsComponent,
    canActivate: [versionsGuard],
  },
  {
    path: SETTINGS_ROUTING.Support,
    title: SETTINGS_ROUTING.Support,
    loadComponent: async () =>
      (await import('@features/partners/builder/settings/support/support.component')).SupportComponent,
    canDeactivate: [canDeactivateGuard],
    resolve: {
      partnerId: partnerIdResolver,
    },
  },
  {
    path: SETTINGS_ROUTING.PlatformAccess,
    title: SETTINGS_ROUTING.PlatformAccess,
    loadChildren: async () =>
      (await import('@features/partners/builder/settings/platform-access/routes')).PLATFORM_ACCESS_ROUTES,
  },
  {
    path: SETTINGS_ROUTING.Nps,
    title: SETTINGS_ROUTING.Nps,
    loadComponent: async () => (await import('@features/partners/builder/settings/nps/nps.component')).NpsComponent,
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: SETTINGS_ROUTING.Trackers,
    loadComponent: async () =>
      (await import('@features/partners/builder/settings/trackers/trackers.component')).TrackersRouteComponent,
    canDeactivate: [canDeactivateGuard],
    resolve: {
      trackers: trackersResolver,
    },
  },
  {
    path: SETTINGS_ROUTING.SignInMethods,
    loadComponent: async () =>
      (await import('@features/partners/builder/settings/sign-in-methods/sign-in-methods.component'))
        .SignInMethodsRouteComponent,
    resolve: {
      signInMethods: signInMethodsResolver,
    },
  },
  {
    path: SETTINGS_ROUTING.Widget,
    title: SETTINGS_ROUTING.Widget,
    loadComponent: async () =>
      (await import('@features/partners/builder/settings/widget/widget.component')).WidgetComponent,
    canDeactivate: [canDeactivateGuard],
  },
];
