import type { ActivatedRouteSnapshot } from '@angular/router';
import type { Trackers } from '@models/partner/trackers';
import type { Observable } from 'rxjs';

import { inject } from '@angular/core';
import { TrackersWebService } from '@webservices/trackers/themes.webservice';

export function trackersResolver(route: ActivatedRouteSnapshot): Observable<Trackers> {
  const trackersWebservice = inject(TrackersWebService);
  const partnerId = route.paramMap.get('partnerId');

  if (partnerId === null) {
    throw new Error('partnerId is required');
  }

  return trackersWebservice.get(partnerId);
}
